import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import Layout from "../components/layout"
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image"
import { Formik, Field } from 'formik';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { updateProfile } from '../state/actions/login/loginAction';
import { navigate } from "gatsby"
import { getMethod, postMethod, putMethod } from '../helper/api';
import { notify } from 'react-notify-toast'

var guserPref = {};
var suserPref= {};
const Preferences = ({ user }) => {

    const [subjects, setSubjects] = useState([])
    const [userPref, setUserPref] = useState({})
    const [isSet, setPrefSet] = useState(false)
    const [refresh, setRefresh] = useState(1)


    async function setPref(pref) {
        
        delete guserPref[pref.name];
        Object.assign(guserPref, { [pref.name]: pref.value });
        console.log(pref.value);
        setUserPref(guserPref);
        setRefresh(refresh+1);
    }

    async function updatePreferences() {
        let subjects = [];
        // guserPref.map(async (pref,i)=>{
        //     let resp = await postMethod(`/faculty-subject-preferences`, { faculty: user.id, subject: i, level:pref });
        //     subjects.push(i);
        // })

        for(var i in guserPref){

	    let id = null;
            for(var j in userPref){
                if(userPref[j].subject===i){
                    id = userPref[i]['id']; break;
                }
            }
           
            if(id!==null){
                let resp = await putMethod(`/faculty-subject-preferences/${id}`, {
                    faculty : user.authenticate.user.id,
                    subject : i,
                    level : guserPref[i]
    
                });
            }else{
                let resp = await postMethod(`/faculty-subject-preferences`, { faculty: user.authenticate.user.id, subject: i, level:guserPref[i] });
            }
            
            subjects.push(i);
        }

        let resp = await postMethod('/users/updatesubjects',{subjects:subjects});
        setTimeout(() => notify.show("Preferences Saved Successfully", "success"), 100);
        navigate('/dashboard/?v='+window.localStorage.getItem('appVersion'));
        
    }

    

    useEffect(() => {
        async function getSubjects() {
            let resp = await getMethod(`/subjects`);
            //console.log(resp.data);
            setSubjects(resp.data);
        }
        async function getUserPref() {
            let resp = await getMethod(`/faculty-subject-preferences`, "", { faculty: user.authenticate.user.id });
            
            for(var i in resp.data){
                Object.assign(guserPref,{[resp.data[i].subject.id]:resp.data[i].level});
                Object.assign(suserPref,{[resp.data[i].subject.id]:resp.data[i].id});
            }

            //console.log("Pref::",guserPref,"::",suserPref);
            setUserPref(resp.data);

	    if(resp.data.length>0){
                setPrefSet(true);
            }
        }

        async function getData() {
            await getSubjects();
            await getUserPref();
        }
        getData()
    }, [])

    console.log(isSet);

    return refresh && (
        <Layout title="My Preferences" >
            <Seo title='Home' />
            <div className="container" key={refresh}>
                <form>
                    {subjects.map((subject, i) => {
                    let bselected = guserPref[subject.id]==='Beginner'?'selected="true"':''
                    let iselected = guserPref[subject.id]==='Intermediate'?'selected="true"':''
                    let aselected = guserPref[subject.id]==='Advanced'?'selected="true"':''

                    //console.log(suserPref, bselected);

                    let readOnly = isSet ?{disabled:'disabled'}:{};

                    return (<div className="row align-items-center gx-3 mb-3" key={i}>
                        <div className="col">
                            <label className="mb-0">{subject.name}</label>
                        </div> 
                        <div className="col">
                            <div className="select-wrapper">
                                <select className="form-control form-control-sm" name={subject.id} {...readOnly} value={guserPref[subject.id]} onChange={(event) => { setPref(event.target) }}>
                                    <option value="" key="0" >--Not Teaching--</option>
                                    <option value="Beginner" key="1" >Beginner</option>
                                    <option value="Intermediate" key="2" >Intermediate</option>
                                    <option value="Advanced" key="3" >Advanced</option>
                                </select>
                            </div>
                        </div>    
                    </div>
                    )}
                    )}
                    {!isSet && <div className="fixed-btn-wrapper">
                        <button className="btn btn-primary w-100" type="button" onClick={updatePreferences}>
                            Update
                        </button>
                    </div>}

                    {isSet && <div className="btn-wrapper">
                        
                            <b>Please contact admin to change the preferences</b>
                       
                    </div>}
                </form>
            </div>    
        </Layout>)
}

export default connect(state => ({ user: state.loginReducer }), null)(Preferences);
